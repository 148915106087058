import { customValidators } from "@/i18n/utils/i18n-validators";
import { validationMessagesResolver } from "@/constants/validationMessages";
import { useSafeI18n } from "@/composables/useSafeI18n";
import {
  helpers,
  required as requiredValidator,
  email as emailValidator,
} from "@vuelidate/validators";

export const formNewsletterValidationRules = (
  otherFieldsRequired?: boolean,
) => {
  const { t } = useSafeI18n();

  let email, required;
  if (typeof useNuxtApp !== "function") {
    email = emailValidator;
    required = requiredValidator;
  } else {
    const receivedCustomValidators = customValidators();
    email = receivedCustomValidators.email;
    required = receivedCustomValidators.required;
  }

  const requiredObject = otherFieldsRequired ? { required } : {};

  return {
    email: {
      required,
      email,
    },
    firstName: requiredObject,
    lastName: requiredObject,
    acceptedTerms: {
      checked: helpers.withMessage(
        t("footer.newsletter.acceptTermsRequired") ||
          validationMessagesResolver.mustBeChecked,
        (value: boolean) => value === true,
      ),
    },
  };
};
